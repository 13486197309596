import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDetail(ctx, idx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/update-content/${idx}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    update(ctx, { idx, ...params }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_BACKEND_SERVER}/manage/update-content/${idx}/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delete(ctx, { idx, ...params }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.VUE_APP_BACKEND_SERVER}/manage/update-content/${idx}/`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
