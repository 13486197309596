<template>
  <div>
    <b-alert
      variant="danger"
      :show="hasLoadError"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'member-inf-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>
    <b-row>
      <b-col
        cols="12"
        xl="8"
        lg="8"
        md="7"
      >
        <update-content-info-card
          v-if="updateContent"
          :update-content="updateContent"
          :content-max-length="contentMaxLength"
          :is-uploading-file="isUploadingFile"
          @input-img-file="inputImgFile"
        />
      </b-col>
      <b-col
        cols="12"
        xl="4"
        lg="4"
        md="5"
      >
        <action-card
          :updateContent="updateContent"
          :is-saving="isSaving"
          :is-valid="isValid"
          @save="updateInfo"
          @turn-on-delete-modal="turnOnDeleteModal"
        />
      </b-col>
    </b-row>
    <delete-modal
      :visible="visibleDeleteModal"
      :reason="deleteReason"
      @ok="deleteInfo"
      @hidden="turnOffDeleteModal"
      @input-reason="inputDeleteReason"
    />
  </div>
</template>

<script>
import {
  BAlert,
  BLink,
  BRow,
  BCol,
} from 'bootstrap-vue'
import viewModel from './viewModel'
import UpdateContentInfoCard from './components/UpdateContentInfoCard.vue'
import ActionCard from './components/ActionCard.vue'
import DeleteModal from './components/DeleteModal.vue'

export default {
  components: {
    BAlert,
    BLink,
    BRow,
    BCol,
    UpdateContentInfoCard,
    ActionCard,
    DeleteModal,
  },
  setup() {
    return {
      ...viewModel(),
    }
  },
}
</script>
