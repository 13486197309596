import {
  ref,
  onMounted,
  onUnmounted,
  computed,
  watch,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import store from '@/store'
import router from '@/router'
import uploadFile from '@/hooks/uploadFile'
import storeModule from './storeModule'

export default function () {
  // 모든 기능에서 공통적으로 사용
  const APP_STORE_MODULE_NAME = 'app-update-content-detail'

  const toast = useToast()
  const makeToast = (variant, title, text) => toast({
    component: ToastificationContent,
    props: {
      title,
      text,
      variant,
      icon: 'AlertTriangleIcon',
    },
  })

  const updateContent = ref()
  const hasLoadError = ref(false)
  const isUploadingFile = ref(false)

  const fetchDetail = () => store.dispatch(`${APP_STORE_MODULE_NAME}/fetchDetail`,
    router.currentRoute.params.idx)
    .then(response => {
      updateContent.value = response.data.data
    }).catch(() => {
      hasLoadError.value = true
    }).finally(() => {
    })

  const inputImgFile = file => {
    isUploadingFile.value = true
    uploadFile(file, 'update_content_info', '')
      .then(response => {
        updateContent.value.img_idx = response.idx
        updateContent.value.img = {
          idx: response.idx,
          url: response.file_url,
          file_name: response.file_name,
          file_type: response.file_type,
          file_size: response.file_size,
        }
      })
      .catch(() => {
        makeToast('danger', `${file.name} 파일 업로드에 실패했습니다`)
      })
      .finally(() => {
        isUploadingFile.value = false
      })
  }

  const isSaving = ref(false)
  const contentMaxLength = 600
  const isValid = computed(() => Boolean(updateContent.value?.title) && Boolean(updateContent.value?.type) && Boolean(updateContent.value?.version) && (updateContent.value?.content?.length < contentMaxLength) && !isUploadingFile.value)

  const updateInfo = () => {
    isSaving.value = true
    store.dispatch(`${APP_STORE_MODULE_NAME}/update`,
      {
        idx: router.currentRoute.params.idx,
        type: updateContent.value.type,
        version: updateContent.value.version,
        title: updateContent.value.title,
        content: updateContent.value.content,
        img_idx: updateContent.value.img_idx,
        link_url: updateContent.value.link_url,
      })
      .then(() => {
        fetchDetail()
        makeToast('primary', '수정에 성공 했습니다')
      }).catch(() => {
        makeToast('danger', '수정에 실패 했습니다')
      }).finally(() => {
        isSaving.value = false
      })
  }

  const deleteReason = ref()
  const inputDeleteReason = value => {
    deleteReason.value = value
  }

  const visibleDeleteModal = ref(false)
  const turnOnDeleteModal = () => {
    deleteReason.value = null
    visibleDeleteModal.value = true
  }
  const turnOffDeleteModal = () => {
    visibleDeleteModal.value = false
  }

  const deleteInfo = () => {
    store.dispatch(`${APP_STORE_MODULE_NAME}/delete`,
      {
        idx: router.currentRoute.params.idx,
        reason: deleteReason.value,
      })
      .then(() => {
        router.push({ name: 'update-content-list' })
        makeToast('primary', '삭제에 성공 했습니다')
      }).catch(() => {
        makeToast('danger', '삭제에 실패 했습니다')
      }).finally(() => {
      })
  }

  onMounted(() => {
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, storeModule)
    fetchDetail()
  })
  onUnmounted(() => {
    if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
  })

  return {
    updateContent,
    hasLoadError,
    fetchDetail,
    inputImgFile,
    isUploadingFile,
    isSaving,
    contentMaxLength,
    isValid,
    updateInfo,
    deleteReason,
    inputDeleteReason,
    visibleDeleteModal,
    turnOnDeleteModal,
    turnOffDeleteModal,
    deleteInfo,
  }
}
