<template>
  <b-card>
    <b-row>
      <b-col cols="6">
        <b-button
          variant="outline-primary"
          block
          :disabled="isSaving || !isValid"
          @click="$emit('save')"
        >
          <b-spinner
            v-if="isSaving"
            small
          />
          저장
        </b-button>
      </b-col>
      <b-col cols="6">
        <b-button
          variant="outline-danger"
          block
          :disabled="updateContent.state !== 'ACTIVE'"
          @click="$emit('turn-on-delete-modal')"
        >
          비활성화
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
  },
  emits: [
    'save',
    'turn-on-delete-modal',
  ],
  props: {
    updateContent: {
      type: Object,
      default: () => {},
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
